import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BaseForm, GeneratedReportDto, getEnumOptions } from '@shared/classes';
import { ViewModeService } from '@shared/services';

@Component({
  selector: 'app-generated-report-item-form',
  templateUrl: './generated-report-item-form.component.html',
  styleUrl: './generated-report-item-form.component.scss',
})
export class GeneratedReportItemFormComponent extends BaseForm<GeneratedReportDto> implements OnInit {
  reportGenerationOptions = getEnumOptions(GeneratedReportDto.ReportGenerationEnum);
  formatOptions = getEnumOptions(GeneratedReportDto.FormatEnum);
  constructor(public viewModeService: ViewModeService) {
    super(viewModeService, 'GENERATED_REPORT');
  }

  ngOnInit(): void {}

  getData() {
    return this.viewModeService.viewMode == 'create'
      ? this.getDataKeyValueFormat()
      : this.getChangedFormValues().updateItems;
  }

  setData(data: any) {
    this.patchFormData({ ...data });
    this.data = data;
  }

  initFormStructure(): void {
    this.formGroup = new FormGroup({
      report: new FormControl(null),
      // fileId: new FormControl(null),
      runAs: new FormControl(null),
      notes: new FormControl(null),
      date: new FormControl(null),
      status: new FormControl(null),
      reportGeneration: new FormControl(null),
      reportScheduler: new FormControl(null),
      format: new FormControl(null),
      // pdfrenderConfig: new FormGroup({
      //   pdfSize: new FormControl(null),
      //   header: new FormControl(null),
      //   footer: new FormControl(null),
      //   coverPage: new FormControl(null),
      //   includeHeader: new FormControl(null),
      //   includeFooter: new FormControl(null),
      //   includeCoverPage: new FormControl(null),
      //   includeTableOfContents: new FormControl(null),
      //   includeReportDetails: new FormControl(null),
      //   exportRaw: new FormControl(null),
      // }),
    });
  }
}
